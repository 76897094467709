export const SpeakersData = [
  {
    id: 1,
    firstName: 'Artur',
    lastName: 'Skowroński',
    title: 'Software Engineering',
    company: 'Tech Corp',
    presentation: "JVM in the Age of AI: Babylon, Valhalla, TornadoVM and friends",
    abstract: "JVM in the Age of AI: Babylon, Valhalla, TornadoVM and friends\n" +
      "\n" +
      "Are you tired of all the hype around yet another LLM-as-a-Service? That's why I want to talk about something more interesting than just another tool for prompt engineers. \n" +
      "\n" +
      "While the entire industry is discussing it, my goal is to explain what needs to happen within the virtual machine for the JVM to become a good platform for Machine Learning and AI. We will discuss hardware and the challenges its evolution poses for the JVM, projects like Valhalla and Babylon, as well as standardization efforts like the JSR381 Visual Recognition API. \n" +
      "\n" +
      "We will also look at initiatives like TornadoVM. This will be an overall birds-eye view to understand how the JVM can meet the demands of contemporary artificial intelligence and machine learning.",
    bio: 'Artur Skowroński - Head of Java/Kotlin Engineering at VirtusLab, he has been in the industry for ten years. During this time, he has had the opportunity to work in various roles, such as Software Engineer, Tech Lead, Architect, and even Technical Product Manager. This diverse experience enables him to approach problems from a holistic perspective.\n' +
      '\n' +
      'He still loves to get his hands dirty - for more than two years, he has been publishing weekly reviews of events in the JVM world - https://vived.substack.com/',
    photo: "artur_skowronski.webp",
    website: 'https://johndoe.com',
    linkedin: 'https://www.linkedin.com/in/arturskowronski/',
    twitter: 'https://twitter.com/johndoe',
    isFeatured: true,
    language: "ENG"
  },
  {
    id: 2,
    firstName: 'Vladimir',
    lastName: 'Alekseichenko',
    title: 'Cloud&AI',
    presentation: "Budowanie własnego OpenAI: Self-Hosting LLM Inference Engines",
    abstract: "Czy chcesz poznać jak uruchamiania się modele LLM lokalnie i skalowania ich do obsługi tysięcy użytkowników? W tej prezentacji podzielę się swoim doświadczeniem wypracowanym w DataWorkshop, pokazując krok po kroku, jak rozpocząć pracę z LLM na CPU przy użyciu narzędzi takich jak Ollama, a następnie zwiększyć wydajność dzięki GPU. Dowiesz się, jak skutecznie radzić sobie z wyzwaniami, takimi jak latencja, przepustowość i wymagania pamięciowe w rzeczywistych systemach produkcyjnych.\n" +
      "\n" +
      "Kluczowym elementem będzie omówienie skalowania rozwiązań, które pozwolą Ci na obsługę dużej liczby równoczesnych użytkowników. Przedstawię zaawansowane techniki, które wykorzystujemy w DataWorkshop, takie jak ciągły batching, PagedAttention i optymalizacja pamięci, z wykorzystaniem narzędzi vLLM, aby znacząco zwiększyć przepustowość.\n" +
      "\n" +
      "Przygotowałem dla Ciebie praktyczne wprowadzenie, konkretne porady oraz wskazówki, jak uniknąć typowych pułapek przy wdrażaniu LLM w środowisku produkcyjnym. Będę mówić po ludzku, więc niezależnie od tego, czy dopiero zaczynasz swoją przygodę z AI, czy jesteś doświadczonym inżynierem ML, znajdziesz tu inspiracje, pomysły i odpowiedzi, które pomogą Ci wykorzystać potencjał LLM w Twoich projektach.\n",
    company: 'Data Insights',
    bio: 'Vladimir is the founder and CEO of DataWorkshop, with over 10 years of hands-on experience in implementing machine learning. Before starting his own company, he served as an architect at General Electric. He specializes in solving complex business problems using artificial intelligence, ML. Vladimir advises Fortune 500 companies, helping them integrate AI/ML and LLMs across industries such as industry, automotive, telecommunications, logistics and so on.\n' +
      '\n' +
      'As the creator of the DataWorkshop community and the organizer of the DataWorkshop Club conference, he actively inspires and educates thousands of people on practical ML and LLM (production ready). He hosts the podcast "Biznes Myśli," dedicated to AI, and is the author of five courses completed by thousands of participants. His graduates work at leading companies like Google, Microsoft, Intel, IBM, Motorola, Nokia, Orange, Santander, PKO, Alior, and Revolut.\n',
    photo: 'vladimir_alekseichenko.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/vladimiralekseichenko/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: true,
    language: "PL"
  },
  {
    id: 3,
    firstName: 'Małgorzata (Rita)',
    lastName: 'Łyczywek',
    title: 'Software Engineering',
    presentation: "O decyzjach w Architecture Decision Record",
    abstract: "Czym są ADR, jak je wdrożyć i w jaki sposób zachęcają one do współodpowiedzialności w podejmowaniu decyzji? Zbadamy, w jaki sposób ADR pomagają stworzyć kulturę inżynierii opartej na współpracy. ADR mogą utrzymać spójność w środowiskach mikrousług poprzez dokumentowanie decyzji i zapewnienie, że zespoły nie będą fragmentować swojej architektury w miarę skalowania. Omówimy również, jak otworzyć rozmowy między zespołami programistów i projektantów, zwłaszcza w większych organizacjach. Wyciągniemy też wnioski z naszych porażek ;)\n",
    company: 'Data Insights',
    bio: "Head of Technology at the CODE\n" +
      "Foundation, software engineer at Wizards of the Coast, educator. She runs a tech blog, flynerd.pl, where she shares knowledge about IT and teaches programming. Mentor at numerous coding workshops for women. Last year, she was honored with the Women Who Code: 100 Technologists To Watch award.",
    photo: 'rita_luczywek.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/ritalyczywek/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: true,
    language: "PL"
  },
  {
    id: 4,
    firstName: 'Kasia',
    lastName: 'Brzozowska',
    title: 'Mobile',
    presentation: "Bezpieczeństwo w świecie komunikatorów czyli czy szyfrowanie danych = prywatność?\" ",
    company: 'Data Insights',
    abstract: "Omówimy topowe aplikacje do komunikacji pod względem zapewnienia prywatności i bezpieczeństwa danych. Dowiecie się jakie cechy powinna mieć aplikacja dbająca o bezpieczeństwo użytkowników.",
    bio: "Kasia jest inżynierem DevSecOps z technicznym zapleczem jako administrator Linuxa, SysOps inżynier i DevOps inżynier. Specjalizuje się w cyberbezpieczeństwie, automatyzacji, rozwiązanich chmurowych. W trakcie swojej kariery była odpowiedzialna za zarządzanie infrastrukturą i odpowiadała za wdrożenie, konfigurację i zabezpieczenie kluczowych części infrastruktury, takich jak sieć, serwery Linuxa czy klastry Kubernetes. Była także częścią zespołu, gdzie odpowiadała za reagowanie na incydenty w przypadku głównych problemów produkcyjnych. Prowadzi organizację Be Like Neo & Trinity, której ideą jest dzielenie się wiedzą z zakresu technologii szczególnie skupiając się na tematach związanych z DevOps, blockchain oraz cyberbezpieczeństwem. Po godzinach Kasia współpracuje z organizacjami Girls Code Fun i Nerds Coding Gang jako mentorka, a także dzieli się wiedzą na wydarzeniach branżowych",
    photo: 'katarzyna_brzozowska.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/katarinabrzozowska/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 5,
    firstName: 'Jarosław',
    lastName: 'Gajewski',
    title: 'Cloud&AI',
    company: 'Data Insights',
    abstract: "This talk explores the exciting world of Large Language Models (LLMs) and their practical applications within the software development lifecycle. We'll delve into the intricacies of deploying and configuring LLMs on your own hardware, weighing the advantages and disadvantages of this approach against cloud-based solutions.\n" +
      "Moving beyond deployment, we'll examine how LLMs can revolutionize various stages of the development process. From assisting developers with code generation and debugging to automating CI pipelines and generating up-to-date documentation, we'll showcase real-world examples and practical use cases.\n" +
      "Join us to discover how harnessing the power of GenAI can enhance your development workflow, boost efficiency, and unlock new possibilities.",
    presentation: "Unleashing the Power of LLMs and GenAI - on prem and in the cloud",
    bio: 'Google Cloud Certified Fellow, Champion Innovator and technology enthusiast. Currently works in Eviden as Cloud Advocate responsible for the architecture of managed services for Google Cloud Platform,  Automation platforms and GenAI accelerators for development . Eviden Distinguished Expert in the area of Cloud and member of Eviden Scientific Community. Co-author of Anthos in Action book. Privately happy husband, father of 3 and board game geek.',
    photo: 'jarekgajewski.webp',
    linkedin: 'https://www.linkedin.com/in/jaroslawgajewski/',
    isFeatured: true
  },
  {
    id: 6,
    firstName: 'Konrad',
    lastName: 'Cłapa',
    title: 'Cloud&AI',
    company: 'Data Insights',
    abstract: "This talk explores the exciting world of Large Language Models (LLMs) and their practical applications within the software development lifecycle. We'll delve into the intricacies of deploying and configuring LLMs on your own hardware, weighing the advantages and disadvantages of this approach against cloud-based solutions.\n" +
      "Moving beyond deployment, we'll examine how LLMs can revolutionize various stages of the development process. From assisting developers with code generation and debugging to automating CI pipelines and generating up-to-date documentation, we'll showcase real-world examples and practical use cases.\n" +
      "Join us to discover how harnessing the power of GenAI can enhance your development workflow, boost efficiency, and unlock new possibilities.",
    presentation: "Unleashing the Power of LLMs and GenAI - on prem and in the cloud",
    bio: 'Konrad Cłapa is a Certified Google Trainer and Lead Cloud Architect working for\n' +
      'Atos R&D. He has over 14 years\' experience in the IT industry. He holds over 40 IT\n' +
      'certification. This includes all 12/12 active Google Cloud Platform certifications making\n' +
      'him the first in the world to achieve it. He is also listed among 40 individuals who hold\n' +
      'Google Cloud Certified Fellow certificate. Sharing knowledge has always been important\n' +
      'to him, so he contributes to the community by acting as a leader for a local Google Cloud\n' +
      'Developer group. Funny fact about Konrad is that till now he never failed a GCP exam\n' +
      'attempt including multiple beta exams that he has taken.\n',
    photo: 'konradcłapa.webp',
    linkedin: 'https://www.linkedin.com/in/konrad-c%C5%82apa-google-cloud-certified-fellow-vcdx-87275bb/',
    isFeatured: true,
    language: "PL"

  },
  {
    id: 7,
    firstName: 'Artur',
    lastName: 'Wojnar',
    title: 'Software Engineering',
    company: 'Data Insights',
    presentation: "Porównanie implementacji\n" +
      "archetypu dostępności\n" +
      "czasowej",
    abstract: "Archetypy to rozwiązania problemów biznesowych. Dzięki ich znajomości łatwiej rozpoznamy elementy składowe naszego projektu - klocki, z których budujemy architekturę.\n" +
      "Jednym z najbardziej popularnych archetypów jest archetyp dostępności.\n" +
      "Podczas mojej prelekcji opowiem o wariacji tego problemu - dostępności czasowej. Omówię definicję obiektową archetypu i szczegółowo opiszę trzy różne implementacje pod kątem ich wydajności w systemach gdzie spodziewamy się dużego ruchu.\n" +
      "Zanim jednak to zrobię, przybliżę czym są archetypy i jaki mają związek z DDD.\n",
    bio: "I'm a software developer with 13 years of experience, but I've been interested in programming for over 20 years. Currently, I work as a hands-on Solutions Architect. My key responsibility is analyzing problem spaces and transforming them into solution spaces using different heuristics and methodologies. My work approach is always purely pragmatic and client-oriented.",
    photo: 'artur_wojnar.webp',
    language: "PL",
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/artur-wojnar-a19349a6/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
  },
  {
    id: 8,
    firstName: 'Konrad',
    lastName: 'Szałkowski',
    title: 'Software Engineering',
    company: 'Data Insights',
    presentation: "Porządkowanie chaosu - Structured concurrency",
    abstract: "Kiedy w naszym świecie panuje porządek jest nam łatwiej się w nim poruszać. Ten dosyć znany fakt jako programiści odkrywamy z radością co parę lat. W Javie 21 dostaliśmy nową zabawkę - wątki wirtualne.\n" +
      "\n" +
      "Jednym z ich głównych haseł reklamowych jest \"Stwórz, wystartuj, zapomnij\" - kusi, do tego wszyscy zachęcają by jak najwięcej takich wątków tworzyć. A wszyscy wiemy co dzieje się w dziecięcych pokojach po urodzinach naszych pociech, po świeżej dostawie zabawek - chaos. Tym razem jednak twórcy Javy starają się uprzedzić fakty i dać nam do użytku całkiem nowe API - Structured Concurrency. Nie jest to odkrycie koła na nowo i wielka sensacja - ale raczej sprawdzony sposób na \"ogarnięcie\" współbieżności w naszych programach.\n" +
      "\n" +
      "Podczas tej prezentacji postaram się Was wprowadzić w koncepcję Structured Concurrency, dlaczego ona działa i dlaczego powstała. Postaram się również zaprezentować ten kod w działaniu.",
    bio: "A Java developer keen on sniffing out what's sizzling in silicon. On a daily basis, I save the world by eliminating legacy code and coffee from the machine. I'm a fan of clean code, design patterns, and the SOLID principles. I'm also a speaker at conferences and meetups, and a co-organizer of the JUG Łódź.",
    photo: 'konrad_szalkowski.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/konrad-sza%C5%82kowski-6a993431/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 9,
    firstName: 'Marcin',
    lastName: 'Milewicz',
    title: 'Software Engineering',
    company: 'Data Insights',
    presentation: "DDD na frontendzie? A komu to potrzebne?",
    abstract: "DDD na frontendzie to z pewnością kolejny buzzword w świecie frontendu, ale czy na pewno wszelkie koncepcje strategicznego DDD mogą być przydatne w tworzeniu aplikacji frontendowych? W prezentacji odpowiemy sobie na pytanie, które podstawowe koncepty DDD da radę użyć w wytwarzaniu frontendu, a przede wszystkim czy to aby zawsze ma sens. Przyjrzymy się również jakie korzyści i problemy może przynieść nam zastosowanie DDD na frontendzie.",
    bio: "A passionate programmer for over two decades. Google Developer Expert (Web Technologies), Principal Developer, Frontend Architect, and Frontend Tech Leader at Xebia Poland. In his free time, he's a Toastmaster, running enthusiast, travel and mountain lover, as well as a fan of strengths-based psychology. Strongly focused on technological innovation and personal growth. Podcaster of the 'Na Froncie' channel.",
    photo: 'marcin_milewicz.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/marcinmilewicz/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 10,
    firstName: 'Michał',
    lastName: 'Mikołajczak',
    title: 'Cloud&AI',
    presentation: "What does the Fox say? What can we make it say? Brief overview of current speech to text and text to speech models and solutions.\n",
    company: 'Data Insights',
    abstract: "Krajobraz sztucznej inteligencji ewoluuje w ostatnich latach bardzo szybko.\n" +
      "Foundation models dla tekstu – old news. Foundation models dla obrazu – old news.\n" +
      "A co z dźwiękiem? Okazuje się, że świecie AI maszyny zyskują coraz bardziej ludzki głos – i to całkiem dosłownie.\n" +
      "\n" +
      "Ta prelekcja skupi się na zaznajomieniu słuchaczy z obecnymi technologiami które to umożliwiają, w szczególności (chodź nie tylko) text to speech i speech to text. Przejdziemy przez najpopularniejsze rozwiązania open source’owe i komercyjne, ich popularne aplikacje, a także aspekty które warto rozważyć (od technicznych, po związane z etyką) przy ich wdrażaniu.\n" +
      "\n" +
      "Jeśli chcesz się dowiedzieć co sztuczna inteligencja może nam “powiedzieć” dzisiaj, ale także to co może wyrazić jutro z obecnymi kierunkami rozwoju, i jakie wyzwania jeszcze przed nią stoją – ta prelekcja jest dla Ciebie.",
    bio: "A 21st-century veteran of the oil industry—data. Together with his organization, datarabbit.ai, he helps clients process and 'refine' data using analytics and AI, extracting actionable insights and enhancing products with innovative features. He has successfully designed and implemented such solutions for international clients of various scales and across a wide range of industries, particularly in the medical field.",
    photo: 'michal_mikolajczak.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/micha%C5%82-miko%C5%82ajczak/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 11,
    firstName: 'Barbara',
    lastName: 'Sobkowiak',
    title: 'Cloud&AI',
    company: 'Data Insights',
    presentation: "Model AI/ML na produkcji, szybki spacer czy bieg z przeszkodami?",
    bio: "Od ponad 11 lat związana z analizą danych każdego rodzaju. Obecnie\n" +
      "skupia się na rozwiązaniach machine learning i AI. W swojej karierze\n" +
      "była również przez wiele lat managerem zespołu data science,\n" +
      "dostarczającego projekty ML end-to-end, zarówno na platformach\n" +
      "on-premise jak i chmurowych.  Łączy na projektach dwa światy:\n" +
      "technologiczny i biznesowy, dbając przy tym, by projekt był wykonany\n" +
      "zgodnie z dobrymi praktykami, a model wspierał działania użytkowników.\n" +
      "Mocno wierzy w to, że aby rozwiązywać problemy biznesowe Klientów,\n" +
      "trzeba z nimi rozmawiać i rozumieć ich zagadnienia, a dopiero potem\n" +
      "tworzyć modele machine learning. Wspiera również poznański oddział Women\n" +
      "in Machine Learning and Data Science (WiMLDS) w działaniach\n" +
      "wspierających kobiety w tym obszarze. W wolnym czasie odkrywa squasha i\n" +
      "nowe zakątki świata, zwłaszcza z pokładu żaglówki.\n",
    abstract: "Stworzenie dobrego modelu AI/ML jest ważne i nieraz bardzo trudne.\n" +
      "Jednak nawet najlepszy model na świecie niewiele będzie wart, jeśli nie\n" +
      "będzie używany przez użytkowników, czyli nie zostanie wdrożony na\n" +
      "produkcję. Wydawałoby się, że po pokonaniu wszystkich przeszkód podczas\n" +
      "tworzenia modelu to udostępnienie go użytkownikom będzie już tylko lekkim\n" +
      "spacerkiem. Często jednak niestety przypomina karkołomne zadanie i\n" +
      "mierzenie się z niekończącą liczba problemów. Co zrobić i o jakich\n" +
      "elementach nie zapomnieć, by jednak wdrożenie na produkcję było jak\n" +
      "najmniej kłopotliwe? O tym opowiem holistycznie podczas prezentacji.",
    photo: 'barbara_sobkowiak.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/barbarasobkowiak/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 12,
    firstName: 'Marcin',
    lastName: 'Siudziński',
    title: 'Cloud&AI',
    company: 'Data Insights',
    presentation: "Falling from the cloud - data platforms' lessons learned",
    abstract: "Platforma danych w chmurze to świetny pomysł! Niezależnie od tego, czy chcesz budować hurtownię danych, data lake, lakehouse czy cokolwiek innego. Jest szybko, tanio, łatwo i efektywnie! Co może pójść nie tak?! Otóż całkiem sporo. Na podstawie pracy dla dziesiątek klientów przy wielu projektach przedstawiamy ekstrakt niekoniecznie dobrych praktyk i ich konsekwencji, które rzeczywiście komuś już się przytrafiły. Jeśli chcesz możesz nauczyć się tego wszystkiego samodzielnie, a jeśli wolisz skorzystać z doświadczeń i wpadek innych osób - posłuchaj 21 przykładów z życia wziętych.\n",
    bio: "Inżynier danych od czasów, gdy to określenie wywoływało zdziwione spojrzenia, a nie lawinę ofert pracy. Z 15-letnim stażem w IT, przeszedł przez wszystkie możliwe 'buzzwordy': DWH, Data Lake, Big Data i Cloud, realizując projekty od korpo-gigantów po lokalnych bohaterów. Jako autoryzowany trener Google dzieli się swoją wiedzą i doświadczeniem podczas komercyjnych szkoleń, a w weekendy ze studentami na Uniwersytecie Adama Mickiewicza w Poznaniu. Brał udział w projektowaniu licznych platform danych dla małych i dużych, wliczając w to hurtownię danych za 5zł (miesięcznie) we własnej firmie, specjalizując się w GCP. Jego zajawką jest nie tylko GCP, ale też kultura organizacyjna, DevOps i metody typu Big Picture Event Storming czy Design Thinking. Prywatnie poza danymi żongluje też pieluchami jako ojciec czwórki dzieci.",
    photo: 'michal_siudzinski.webp',
    linkedin: 'https://www.linkedin.com/in/siudzinskim/',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 13,
    firstName: 'Tobiasz',
    lastName: 'Olejnik',
    title: 'Mobile',
    company: 'Appchance',
    presentation: "Getting Started with GraphQL in Flutter",
    bio: "Mobile Team Leader at Appchance with 8 years of experience in the mobile application industry. Started as an Android developer, later transitioning to Flutter. Passionate about mentoring new talents. Enjoys traveling and playing the guitar in free time.",
    abstract: "In this session, participants will learn the basics of integrating GraphQL with Flutter. We’ll discuss the key differences between REST and GraphQL, explore the benefits of using GraphQL in mobile applications, and walk through the setup process in Flutter. The talk will conclude with examples of queries and mutations, empowering you to make the most of this technology.\n",
    photo: 'tobiasz_olejnik.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/tobiaszolejnik/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 14,
    firstName: 'Patryk',
    lastName: 'Połeć',
    title: 'Mobile',
    company: 'FutureMind',
    presentation: "What’s Buzzing in Android A11y?",
    bio: "Passionate about the mobile world and dedicated to ensuring personal safety and privacy online. A proud Android developer with a love for both dogs and cats.",
    abstract: "Explore the often-overlooked details that make mobile accessibility truly effective. This isn’t about the basics—it’s about refining the experience for everyone. Perfect for developers ready to move from functional to genuinely user-centered design!",
    photo: 'patryk_polec.webp',
    linkedin: 'https://www.linkedin.com/in/patryk-polec/',
    isFeatured: false,
    language: "ENG"
  },
  {
    id: 15,
    firstName: 'Piotr',
    lastName: 'Prus',
    title: 'Mobile',
    company: '',
    presentation: "Bringing Your Canvas Creations to Life with Jetpack Compose\n",
    bio: "Self-taught Android developer with a deep passion for tackling challenges in UI and architecture. He thrives on the opportunity to push the boundaries of what is possible in mobile app development. Currently, Piotr is enamored with Jetpack Compose and Kotlin Multiplatform Mobile, spending countless hours honing his skills and exploring their potential. Beyond his technical expertise, Piotr actively seeks to connect with new people in the industry. He is a firm believer in the power of collaboration and the value of networking. Always eager to learn from others and share his knowledge, Piotr enthusiastically engages with fellow developers, fostering meaningful connections that contribute to personal and professional growth. Piotr's knowledge and enthusiasm have earned him invitations to speak at tech conferences. He is sharing his insights and experiences on various topics around Android development. Piotr's always try to speak easy about complicated subjects. He also delivers insightful articles that provide practical tips, explore emerging trends, and delve into the nuances of Android development.",
    abstract: "Take your Android UI development to the next level with the power of Canvas and Jetpack Compose! This session dives deep into crafting astonishing custom graphics, from beginner-friendly shapes to expert-level effects.\n" +
      "\n" +
      "\n" +
      "What you'll learn:\n" +
      " - Explore production examples showcasing the versatility of Canvas in Jetpack Compose.\n" +
      "- Delve into algorithms and mathematical calculations for precise pixel positioning\n" +
      "- Embrace Blend Modes: Gain a comprehensive understanding of Blend Modes and their impact on canvas rendering.\n" +
      "- Bring it to Life: Discover powerful functions and APIs to seamlessly animate your custom graphics within Jetpack Compose.\n" +
      "\n" +
      "\n" +
      "This session is perfect for:\n" +
      "Android developers curious about pushing the boundaries of UI design.  Anyone looking to create visually stunning and interactive experiences.\n" +
      "\n" +
      "\n" +
      "Join me and learn the secrets to animation and breathe life into your custom graphics.\n",
    photo: 'piotr_prus.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/piotrprus/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "ENG"
  },
  {
    id: 16,
    firstName: 'Jarosław',
    lastName: 'Michalik',
    title: 'Mobile',
    company: '',
    presentation: "Pair Programming with AI",
    bio: "My main area of expertise is Android development. – I'm helping companies build better products. In addition to software development and consulting, I'm helping developers by giving talks, conducting workshops and mentoring. I founded KotlinTesting.com – project built to help Android/Kotlin developers navigate efficiently in the world of unit testing. Outside of tech, I play volleyball as an outside hitter and I'm having fun creating contemporary electronic music.",
    abstract: "Generative AI is revolutionizing software development, but most developers are stuck asking it to “just write code.\"\n" +
      "AI assistant can be a powerful pair programming partner, guiding developers through more than just syntax. Without the right techniques and ethical considerations, relying solely on AI can lead to poor quality and even harmful outcomes.\n" +
      "In this talk we'll explore advanced AI pair programming strategies, effective prompt engineering, and ethical frameworks to ensure AI enhances, not replaces, human creativity.\n" +
      "Audience levels\n" +
      "Beginner\n",
    photo: 'jaroslaw_michalik.webp',
    website: 'https://janesmith.com',
    linkedin: 'https://www.linkedin.com/in/jaroslawmichalik/',
    twitter: 'https://twitter.com/janesmith',
    isFeatured: false,
    language: "ENG"
  },
  {
    id: 17,
    firstName: 'Tomasz',
    lastName: 'Jędrzejewski',
    title: 'Software Engineering',
    presentation: "Co wiesz o SWOICH metrykach?\n",
    company: 'Allegro',
    abstract: "Metryki i systemy monitoringu to kluczowe komponenty systemów rozproszonych. Przypominają nam o swoim istnieniu, gdy w nocy budzi nas alarm :wink: , jednak cel ich istnienia jest prosty: pomóc nam rozeznać się w sytuacji i wesprzeć w rozwiązaniu awarii. Ale… co tak naprawdę wiesz o SWOICH metrykach? Czy nie zdarzyło Ci się, że w obliczu awarii patrzyłeś na wykresy i nie rozumiałeś, co właściwie na nich widzisz? Albo czy nie zmarnowałeś choć raz kilku godzin na debugowaniu problemu tylko po to, by odkryć, że metryki cały czas Cię okłamywały?\n" +
      "\n" +
      "W tej prezentacji pragnę zwiększyć świadomość problemu źle zdefiniowanych metryk i braku doświadczenia w pomiarach. Niektórzy mają tę wiedzę w małym palcu od lat, jednak istnieje też duża grupa developerów (również seniorów!), dla których jest to nowy temat lub którzy nie trafili nigdy na projekt, gdzie mogliby się go nauczyć. Pokażę, jak tym problemom zaradzić oraz jak konwencje mogą pomóc Twojemu zespołowi budować lepsze metryki oraz wymieniać się tym doświadczeniem. Te konwencje sprawdziły się u mnie, może też okażą się pomocne dla Ciebie?\n" +
      "\n" +
      "To nie jest prezentacja o narzędziach, ani o frameworkach. To jest prezentacja o umiejętnościach, które przydadzą Ci się w niejednym projekcie i technologii.",
    bio: "Tomasz pracuje jako Principal Software Engineer w Allegro, największej platformie zakupowej w naszej części Europy. Jako PSE wspiera zespoły z obszaru Delivery Experience, a także jest zaangażowany w inicjatywy ogólnofirmowe z zakresu skalowalności, jakości kodu i dokumentacji technicznej. Lubi pomagać innym inżynierom i dzielić się swoim doświadczeniem, a prywatnie lubi podróżować tygodniami po Europie z plecakiem pieszo lub rowerem.",
    photo: 'tomasz-jedrzejewski.webp',
    website: 'https://janesmith.com',
    linkedin: '',
    language: "PL"
  },
  {
    id: 18,
    firstName: 'Michał',
    lastName: 'Michalczuk',
    title: 'Mental Health',
    presentation: "Mniej znaczy więcej? O 4 dniowym tygodniu pracy",
    company: '',
    abstract: "4 dniowy tydzień pracy. Brzmi idyllicznie. To właśnie mój tydzień - cała firma ma wolne piątki. Od poniedziałku do czwartku pracujemy po 8 godzin, jesteśmy w stanie zamknąć się z pracą w 32 godzinach.\n" +
      "\n" +
      "Wow. Za tym efektem-wow stoi odpowiednia kultura organizacji, dobre ustalenie celów i zgranie się z klientami którzy pracują w regularnych 40 godzinach.\n" +
      "\n" +
      "Ale czy zawsze jest wow? Czy nie ma pokusy “dokręcenia” większej ilości godzin gdy w projekcie jest ogień? Czy są jakieś ukryte koszty i pułapki?\n" +
      "\n" +
      "Pracuję tak od ponad 2. lat, i chętnie podzielę się z wami moimi przemyśleniami o 4day workweek, o elastyczności i granicach między pracą a życiem. Oraz o tym skąd popularność ruchu 4day workweek w ostatnich latach.\n",
    bio: "Senior Software Engineer and consultant at Tektit Consulting. IT trainer at Infoshare Academy. Previously worked on Jira Cloud and Atlassian Forge.\n" +
      "\n" +
      "He talks and records content on front-end and web development topics. One of the talking heads on “Śniadanie z Programowaniem” and “Z Archiwum IT” by [JustJoin.it](http://justjoin.it/)\n",
    photo: 'michał_michalczuk.webp',
    linkedin: 'https://www.linkedin.com/in/ritalyczywek/',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 27,
    firstName: 'Paweł',
    lastName: 'Kiszczak',
    title: 'Cloud&AI',
    presentation: "Druga generacja pierzastych - historia i zastosowanie rodziny modeli Bielik V2\n",
    company: '',
    abstract: "Rodzina modeli Bielik V2.x to dowód na to, że nic nie powstrzyma grupy zapaleńców chcących zmienić otaczającą ich rzeczywistość. Dowód namacalny, ponieważ jest dostępny dla każdego w zaciszu jego domu czy mieszkania. Przekonaj się sam jak można użyć (prawdopodobnie) najlepszego polskiego dużego modelu językowego w prawdziwych, biznesowych zadaniach. Wszystko to i jeszcze więcej w oparach dobrej humoru i merytorycznej dyskusji okraszonej (miejmy nadzieję) pizzą.\n",
    bio: "Za dnia pracuje jako Data Scientist w dziale R&D we wrocławskim software house Deviniti, gdzie rozwija nowe rozwiązania oparte na AI. Po godzinach pełni rolę DS/ML Engineer w Fundacji Speakleash, gdzie między innymi współtworzy model językowy Bielik. Dzięki doświadczeniu zdobytemu w  projektach badawczo-rozwojowych łączy praktyczną wiedzę z zakresu sztucznej inteligencji i uczenia maszynowego z solidnym zapleczem biznesowym. Prywatnie fan memów, muzyki oraz japońskich bajeczek. Można go spotkać na rowerze albo jedzącego na mieście. To drugie zdecydowanie częściej.",
    photo: 'paweł_kiszczak.webp',
    linkedin: 'https://www.linkedin.com/in/paveu-kiszczak/',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 19,
    firstName: 'Maria',
    lastName: 'Halicka',
    title: 'Mental Health',
    presentation: "Stres i antystresowy mindset",
    company: '',
    bio: "Jestem socjologiem (studia na Uniwersytecie Wrocławskim), ale również Certyfikowanym Konsultantem Kryzysowym po ukończonym szkoleniu w Akademii Instytutu Pozytywnej Terapii Kryzysu, szkolenie wg. metody RAPID - modelu interwencji rekomendowanego przez WHO (Światową Organizację Zdrowia) w zakresie pierwszej pomocy psychologicznej.\n" +
      "\n" +
      "Pierwsza pomoc psychologiczna to główny obszar moich zainteresowań, także z uwagi na fakt, że jestem strażakiem ratownikiem Ochotniczej Straży Pożarnej, ratownikiem KPP, przewodnikiem psa ratowniczego w specjalności terenowej certyfikowanego przez Państwową Straż Pożarną, z kilkudziesięcioma akcjami ratowniczymi na koncie.\n" +
      "\n" +
      "Od roku 2023 mam zaszczyt być Dyrektorem Biura Konsultacji Kryzysowych Polskiego Towarzystwa Ekspertów i Biegłych Sądowych, gdzie także działam na płaszczyźnie edukacyjnej w ww. zakresie. W całości utożsamiam się z zakresem pojęcia BYĆ w ujęciu filozofii Ericha Fromma, a także fascynuję się psychologiczną koncepcją RESILIENCE. Być może dlatego aktualnie dodatkowo studiuję psychologię, ale możliwe również, że fakty te wynikają z wysokiego poziomu empatii i wrażliwości, która mnie najlepiej opisuje. Zajmuję się udzielaniem wsparcia głównie osobom dorosłym i młodzieży.\n" +
      "\n" +
      "W mojej pracy - jako certyfikowany konsultant kryzysowy zajmuję się ogólnopojętą pierwszą pomocą psychologiczną (w sytuacjach kryzysowych). Udzielam wsparcia psychologicznego, oceniam poziom funkcjonowania, dostarczam informacji - w ramach kompetencji konsultanta. W przypadku takiej potrzeby przekierowuję do odpowiednich specjalistów (z różnych dziedzin, zarówno psychologicznych, ale także prawnych, medycznych czy z zakresu interwencji kryzysowych).\n" +
      "Działam wg amerykańskiego modelu RAPID, modelu interwencji rekomendowanego przez WHO (Światową Organizację Zdrowia), którego założeniami są:\n" +
      "R - Reflective listening - Refleksyjne słuchanie + budowanie relacji\n" +
      "A - Assessment of needs - Ocena potrzeb i funkcjonowania\n" +
      "P - Prioritization – Priorytetyzacja\n" +
      "I - Intervention – Interwencja (plan i strategia pomocy)\n" +
      "D - Disposition – Dyspozycja\n" +
      "\n" +
      "Skupiam się na konstruktywnym podejściu do kryzysu i wykorzystaniu doświadczeń kryzysowych w budowaniu nowej rzeczywistości, pracuję z uwzględnieniem założeń filozofii Resilience - pozytywnej adaptacji.\n",
    abstract: "Całkowita wolność od stresu to śmierć, gdyż stres obecny jest w każdym ludzkim działaniu”. H. Selye\n" +
      "\n" +
      "- Stres towarzyszy nam codziennie, szczególnie w dzisiejszym przebodźcowanym i ultra intensywnym świecie – ale czy musi być on naszym przeciwnikiem, czy wręcz wrogiem?\n" +
      "\n" +
      "Odpowiednie podejście do tematu stresu może sprawić, że stanie się on potężnym sojusznikiem. Korzystając z gościnności organizatorów DevFest Poznań, opowiem o praktykach antystresowych i nowoczesnych technologiach, takich jak np. VR, które pomagają skutecznie radzić sobie z napięciem, ratują w sytuacjach awaryjnych, czy po prostu - pomagają w życiu codziennym.\n" +
      "\n" +
      "Dołącz do nas, by dowiedzieć się, jak dzięki nauce i technologii znaleźć balans, radzić sobie z presją i przekuć stres w pozytywną siłę do działania.\n" +
      "\n" +
      "Otrzymasz praktyczne, naukowo potwierdzone informacje, ale też garść solidnej psychologicznej wiedzy - same konkrety, bez koloryzowania rzeczywistości❗\n" +
      "(za to z poczuciem humoru i na luzie! 💪)\n",
    photo: 'maria_halicka.webp',
    linkedin: 'https://www.linkedin.com/in/maria-h-339aa8142/',
    isFeatured: false,
    language: "PL"
  },
  {
    id: 20,
    firstName: 'Albert',
    lastName: 'Wolszon',
    title: 'Mobile',
    presentation: "Internationalization (and not only)\n",
    abstract: "How should you prepare your Flutter app for introducing it on a global market? Is there something other than the content language and date format? Let's find out what to expect with internationalizing apps and how to do that in Flutter, along with some statistics and fun facts.",
    company: '',
    bio: "Senior Flutter Developer @ LeanCode\n" +
      "Flutter developer since Flutter 1.0.0. Specializing in UI development and internationalization. Creator of Arbify - a Flutter-dedicated translation management system, and poe2arb translation transpiler tool. After work flying as a private pilot.\n",
    photo: 'albert_w.webp',
    linkedin: 'https://www.linkedin.com/in/maria-h-339aa8142/',
    isFeatured: false,
    language: "ENG"
  },
  {
    id: 21,
    firstName: "Ewelina",
    lastName: "Skowron",
    title: "Mental Health",
    company: "",
    presentation: "AI, Technologia... i Psychologia: Nowa Era Zdrowia Psychicznego?\n",
    bio: "Programistka, której pasją jest tworzenie innowacyjnych produktów zmieniających życie ludzi. Już od początku swojej kariery zakochała się w Androidzie, widząc w nim nieograniczone możliwości rozwoju. Wierzy, że technologia powinna być narzędziem do rozwiązywania realnych problemów ludzi. W swojej pracy kładzie duży nacisk na jakość, użyteczność i dostępność tworzonych rozwiązań. Dodatkowo angażuje się jako mentorka oraz organizatorka w GDG, gdzie dzieli się swoją wiedzą i wspiera rozwój społeczności technologicznej. Jest również zwolenniczką zdrowia psychicznego, uważając, że tylko wypoczęty i zdrowy umysł może generować wartościowe pomysły i rozwiązania.",
    abstract: "Podczas prelekcji połącze 3 kluczowe obszary - AI, technologie oraz psychologię. Zastanowimy się jak zmieniająca się technologia i jej rozwój wpływa na zdrowie psychiczne. Czerpiąc z doświadczeń jako programistka Androida, pracy z humanoidalnym robotem Pepperem oraz mojego czasu spędzonego w Me4health, Instytucie Zdrowia Psychicznego, podzielę się cennymi lekcjami na temat tego, jak AI i technologia mobilna kształtują się w kontekście naszego  samopoczucia. Przejrzymy jakie są możliwości i ograniczenia. Jak technologia może nam pomóc, a kiedy zaszkodzić. Wspólnie zastanowimy się, gdzie jest granica...  \n",
    photo: "ewelina_skowron.webp",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/ewelinaskowron/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 22,
    firstName: "Paweł",
    lastName: "Węgier",
    title: "Mental Health",
    company: "",
    presentation: "Pewność siebie, żonglerka i cukier puder - jak przestać się bać i zaprzyjaźnić się ze sobą",
    bio: "Paweł Węgier - certyfikowany Trener Mentalny i jedyny w Polsce Trener Spokoju. Zdrowiem mentalnym zajmuje się od 2014 roku. Kierunkiem jego działań jest, aby Polki i Polacy na co dzień odczuwali więcej spokoju i szczęścia.\n" +
      "\n" +
      "Do tej pory prowadził warsztaty i webinary dla największych firm w Polsce:\n" +
      "\n" +
      "Orange Polska S.A.\n" +
      "ING Hubs Poland\n" +
      "Żabka Polska Sp. z o.o.\n" +
      "Amazon Web Services\n" +
      "Instytut Zdrowia Mentalnego\n" +
      "TDJ S.A.\n" +
      "BCG Platinion\n" +
      "\n" +
      "\n" +
      "oraz Netcompany, Symfonia Sp. z o.o., Sinfonia Varsovia, Fundacja IT Girls, Uniwersytet Ekonomiczny we Wrocławiu, Uniwersytet Ekonomiczny w Krakowie, Nowa Era i wiele innych. \n" +
      " \n" +
      "\n" +
      "Jest autorem artykułów dla takich portali jak Świat Kobiecej Mocy, Akademia Trenerów Mentalnych, Top Guitar. Jest autorem podcastu oraz kanału na YouTube, który skupia się na budowaniu spokoju poprzez świadome działanie. \n" +
      "\n" +
      "\n" +
      "Poza warsztatami prowadzi również konsultacje indywidualne w Katowicach oraz online.  \n" +
      "\n" +
      "\n" +
      "Poza rozwojem osobistym pasjonuje się grą na gitarze. Motto, którym się kieruje: Nikt nie przeżyje Twojego życia za Ciebie, więc „get to work” i żyj!\n",
    abstract: "Paweł w swojej pracy uczy, jak budować pewność siebie i zdrowe podejście do stresu, a także jak odnaleźć równowagę w codziennych wyzwaniach – bez zbędnych trików i cukru pudru! 😉\n" +
      "Dzięki jego warsztatom uczestnicy znajdują sposoby na bardziej świadome życie i budowanie trwałego wewnętrznego spokoju.\n" +
      "\n" +
      "🔎 Szukasz sposobów na rozwój mentalny, chcesz przekuć stres w pozytywną siłę i zacząć lepiej rozumieć siebie? Paweł Węgier podzieli się technikami i przemyśleniami, które pomogły już setkom osób w Polsce. To solidna dawka konkretów, poparta jego wieloletnim doświadczeniem!\n",
    photo: 'pawel_wegier.webp',
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/pawelwegier/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 23,
    firstName: "Marta",
    lastName: "Drozdowska",
    title: "Mental Health",
    company: "Me4Health",
    bio: "Absolwentka i pasjonatka psychologii. Senior HR Business Partner w obszarze IT. Certyfikowany Coach ICC oraz Trenerka Biznesu. Mentorka, autorka programów mentoringowych oraz autorskich warsztatów przygotowujących do roli Mentora/ki Wolontariuszka Fundacji IT Girls, wspierająca kobiety i dziewczynki w ich decyzjach i drodze do świata IT Mama dwóch wspaniałych chłopców, odpoczywająca aktywnie lub z dobrą książką.",
    abstract: "", // todo
    presentation: "", // todo
    photo: "marta_drozdowska.webp",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/marta-drozdowska/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 24,
    firstName: "Panel",
    lastName: "Dyskusyjny",
    title: "Mental Health",
    company: "",
    photo: "",
    presentation: "IT z sercem: o zdrowiu psychicznym, różnorodności i rolach damsko-męskich",
    abstract: "O wyzwaniach związanych z przypisywaniem „tradycyjnych” ról płciowych, podejściu do różnorodności i równych szans w biznesie. Porozmawiamy o programach dla kobiet i alternatywach dla mężczyzn, a także o tym, co współcześnie oznaczają kobiecość i męskość. Pojawi się także refleksja na temat unikalnych problemów i wyzwań obu płci.",
    bio: "Prowadząca: Ewelina Skowron \n" +
      "" +
      " " +
      "Paneliści/tki:\n" +
      "- Magdalena Moskal\n" +
      "- Marta Drozdowska\n" +
      "- Paweł Węgier\n" +
      "- Filip Tarachowicz\n" +
      "\n",
    isFeatured: false,
    exclude: true,
    language: "PL"
  },
  {
    id: 25,
    firstName: "Filip",
    lastName: "Tarachowicz",
    title: "Mental Health",
    presentation: "Jak wspierać siebie i innych w żałobie w miejscu pracy i nie tylko",
    company: "",
    abstract: "Utrata bliskiej osoby przewraca życie do góry nogami. Można odnieść wrażenie, że wszystko wokół zamiera, choć świat dalej biegnie swoim rytmem. Szczególnie świat zawodowy - przecież „praca sama się nie zrobi”… Jak zająć się sobą, swoimi emocjami i swoją żałobą w miejscu pracy? Jak można wesprzeć kolegę lub koleżankę w żałobie? Jak w ogóle przebiega żałoba i czy ma swój koniec? Na te i inne pytania postaramy się znaleźć odpowiedź podczas prelekcji.\n",
    bio: "Założyciel i prezes Fundacji Panda Team im. Michałka Tarachowicza, która wspiera rodziny w żałobie i chorobie. Laureat konkursu “Poznański Wolontariusz Roku 2022” w kategorii indywidualnej. Uczestnik Programu Liderskiego Polsko-Amerykańskiej Fundacji Wolności. Członek Instytutu Dobrej Śmierci. W fundacji otacza opieką rodziców, dzieci i inne osoby po stracie. Prowadzi Hope Spot – miejsce z nadzieją, w którym każdy może znaleźć pomoc w żałobie i kryzysie. Podejmuje się tematów trudnych, które w Polsce wciąż są tematami tabu. Wspiera mężczyzn oraz inne osoby, których żałoba często nie jest uznawana przez społeczeństwo. Edukuje, organizuje kręgi żałoby, warsztaty, spotkania. Zarządza zespołem pracowników fundacji i wolontariuszy oraz nadaje bieg działaniom fundacyjnym, pielęgnując tym samym pamięć o synku. Dba o relacje z mediami, biznesem oraz darczyńcami. Pomysłodawca i twórca m.in. świątecznej akcji charytatywnej Panda Claus oraz programu wsparcia dzieci i młodzieży w żałobie. Z zawodu social media manager, marketingowiec. Współtwórca marki Bilikid, wydawca książek. W wolnych chwilach trenuje improwizację sceniczną oraz tworzy kolaże w ramach arteterapii. ",
    photo: "filip_tarachowicz.webp",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/filiptarachowicz/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 26,
    firstName: "Ankur",
    lastName: "Roy",
    title: "Cloud&AI",
    presentation: "Workshop on Responsible AI with a focus on the education sector (2h)",
    bio: "I am a Solutions Architect at Online Partner AB. I have worked on several projects involving Google Cloud services and Google Workspace along with multiple other technology stacks and platforms.\n" +
      "\n" +
      "Some important things to know about me:\n" +
      "\n" +
      "I have all 11 GCP certifications.\n" +
      "I have recently published a book on the use of Python in DevOps and am currently working on a book regarding passing the Google Cloud Professional Data Engineer certification exam.\n" +
      "I am passionate about working out, writing, and content creation.\n" +
      "I love public speaking and have done many speaking appearances including one recently for a webinar at Google's Oslo headquarters.",
    abstract: "Currently the education sector has become a hotbed for use of Generative AI, particularly among young people under the age of 18, which results in many legal issues that have potentially arisen. Generative AI is here to stay and will most likely be used by students for a very long time. The question becomes how to handle it responsibly and how to take mitigating steps in order to keep juvenile personal data in tact and to use AI while following regulations and best practices for information security. The workshop will go through these topics from the standpoint of the users, developers and legal representatives.",
    photo: "ankur_roy.webp",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/filiptarachowicz/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "ENG"
  },
  {
    id: 26,
    firstName: "Michał",
    lastName: "Jackowski",
    title: "Panel dyskusyjny",
    presentation: "",
    company: "",
    abstract: "Utrata bliskiej osoby przewraca życie do góry nogami. Można odnieść wrażenie, że wszystko wokół zamiera, choć świat dalej biegnie swoim rytmem. Szczególnie świat zawodowy - przecież „praca sama się nie zrobi”… Jak zająć się sobą, swoimi emocjami i swoją żałobą w miejscu pracy? Jak można wesprzeć kolegę lub koleżankę w żałobie? Jak w ogóle przebiega żałoba i czy ma swój koniec? Na te i inne pytania postaramy się znaleźć odpowiedź podczas prelekcji.\n",
    bio: "Visiting Scholar at the Faculty of Law, University of Cambridge, Executive MBA at ESCP Europe, International Cooperation Leader of the AI Working Group at the Ministry of Digitital Affairs, member of Plenary Group drafting LLM Code of Practice for EU AI Office, law professor (SWPS University), attorney and tax advisor with over 20 years of experience as an entrepreneur. Advisor and representative of the technology industry in difficult legislative processes. Co-founder of DSK Law Firm - a law and tax firm specializing in advising the IT sector, and co-founder of innovative startups AnyLawyer and LexDigital. Arbitrator of the arbitration court at the Polish Chamber of Information Technology and Telecommunications. Advisor and representative of the technology industry in difficult legislative processes. Author of several books and numerous scientific publications in the field of law. A promoter of knowledge at the intersection of law and AI - he hosts the Monday Bagel podcast on YT, where he regularly publishes interviews with scientists, legal practitioners and AI experts. Privately - he is a marathon runner and skier, and enjoys reading books - especially about digital transformation in the future, medieval history and Asian culture.\n",
    photo: "michal_jackowski.webp",
    linkedin: "https://www.linkedin.com/in/michal-jackowski-prof/",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 27,
    firstName: "Dota",
    lastName: "Szymborska",
    title: "Panel dyskusyjny",
    presentation: "",
    company: "",
    abstract: "",
    bio: "Doktora etyki, magistra filozofii i stosowanych nauk społecznych, TOP 10 Women in Data Science w Polsce (2024), TOP 100 in AI (2022). Mówczyni TEDxa, wykładowczyni akademicka (Uniwersytet WSB Merito). Zajmuje się etyką nowych technologii, publikuje o AI, prowadzi szkolenia również dla biznesu z etyki generatywnej sztucznej inteligencji. Maratonka, triathlonistka obecnie uczy się grać w golfa (w symulatorze i na polu).",
    photo: "dota_szymborska.webp",
    linkedin: "https://www.linkedin.com/in/dotaszymborska/",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 29,
    firstName: "Marek",
    lastName: "Śliwiński",
    title: "Panel dyskusyjny",
    presentation: "",
    company: "",
    abstract: "",
    bio: "Specjalizuje się w obszarze HR z dużym naciskiem na rozwój linii biznesowych działających w segmencie rekrutacji i zatrudniania cudzoziemców. Obecnie prowadzi 10 firm – siedem na terenie Polski i trzy na terenie Ukrainy w ramach Grupy Worklife, w której pełni funkcję prezesa zarządu. Ma duże doświadczenie w rozwoju spółek poza granicami Polski w sektorze usług HR oraz obsługi procesów organizacji wymaganych zasobów ludzkich opartych na obcokrajowcach na potrzeby przedsiębiorstw działających na terenie Polski. Ma kilkunastoletnie doświadczenie w pełnieniu funkcji zarządczych i kierowniczych. Prywatnie jest miłośnikiem sportów: golfa, narciarstwa, strzelectwa oraz wyścigów samochodowych",
    photo: "marek_sliwinski.webp",
    linkedin: "https://www.linkedin.com/in/marek-śliwiński-b6aa5956/",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 30,
    firstName: "Magdalena",
    lastName: "Moskal",
    title: "Mental Health",
    company: "",
    photo: "magdalena_moskal.webp",
    presentation: "",
    abstract: "",
    bio: " Jestem psychoterapeutką i psycholożką. Wierzę, że energia podąża za uwagą, a moment zmiany na lepsze jest teraz. W mojej pracy kieruję się otwartością, ciekawością, zrozumieniem i empatią. \n" +
      "\n" +
      "Skupiam się na Pacjencie i dobraniu holistycznych sposobów pracy, które pomagają mieć dla siebie większe zrozumienie a dzięki temu częściej czuć spokój, radość, spełnienie. \n" +
      "\n" +
      "Mam ponad 14 lat doświadczenia pracy na stanowiskach managerskich w biznesie. Łączę analityczny i kreatywny sposób myślenia aby dawać narzędzia, które pomagają dbać o optymalną kondycję zdrowia psychicznego (wellbeing) i zadowolenie z pracy. \n" +
      "\n" +
      "Jestem magistrantką dwóch kierunków studiów: Filologii Polskiej i Psychologii (Uniwersytet SWPS). Ukończyłam kurs psychoterapii poznawczo-behawioralnej w Centrum Terapii Poznawczo Behawioralnej, jestem certyfikowaną terapeutką pracy z ciałem i terapii bezsenności (CBT-I). Wciąż szkolę się i uzupełniam swoje komepetencje w zakresie terapii akceptacji i zaangażowania i. technik 3 fali (terapia schematów, uważność, relaksacja).\n" +
      "\n" +
      "Moim celem jest to, aby pacjent czuł realną i korzystną zmianę swojego samopoczucia w bezpiecznej i przyjaznej przestrzeni relacji terapeutycznej.\n" +
      "\n",
    linkedin: "https://www.linkedin.com/in/magdalena-dominika-moskal/",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 31,
    firstName: "Andrzej",
    lastName: "Siemion",
    title: "Panel dyskusyjny",
    company: "",
    photo: "andrzej_siemion.webp",
    presentation: "",
    abstract: "",
    bio: "Pasja do rozwiązywania problemów i wrodzona ciekawość działania różnych rzeczy zaprowadziły go do GFT, gdzie zajmuje się automatyzacją procesów. Z wykształcenia fizyk z doświadczeniem w C/C++, Andrzej na co dzień pracuje z Terraform, Ansible i w chmurze (GCP, Azure). W trakcie doktoratu w dziedzinie holografii syntetycznej i projekcji holograficznej realizował projekty związane z programowaniem równoległym na kartach graficznych, telekomunikacją radiową i optyczną, a nawet… technologiami kosmicznymi! Jako mentor i trener w GFT dzieli się swoją wiedzą z innymi, a na Uniwersytecie Koźmińskiego wykłada w ramach podyplomowych programów Google i GFT. Andrzej to także entuzjasta IoT, który uwielbia nietypowe projekty dla przemysłu – szczególnie te pozornie niemożliwe do zrealizowania. W pracy stawia na stabilność rozwiązań i zawsze pyta „dlaczego?”, wierząc, że to klucz do rozwiązania każdej zagadki.\n",
    linkedin: "https://www.linkedin.com/in/andrzej-siemion/",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 32,
    firstName: "Panel",
    lastName: "Dyskusyjny‎",
    title: "Mental Health",
    company: "",
    photo: "",
    presentation: "Czy możemy zjeść ciastko i mieć ciastko? Innowacje, etyka i prawo w dobie sztucznej inteligencji",
    abstract: "", // todo
    bio: "Prowadząca: Anna Proch \n" +
      "" +
      " " +
      "Paneliści/tki:\n" +
      "- Dota Szymborska\n" +
      "- Marek Śliwiński\n" +
      "- Michał Jackowski\n" +
      "- Michał Zacher\n" +
      "- Krystian Bergmann\n" +
      "\n",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/magdalena-dominika-moskal/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    exclude: true,
    language: "PL"
  },
  {
    id: 33,
    firstName: "Anna",
    lastName: "Proch",
    title: "Panel dyskusyjny",
    company: "CEO of digital agency DP Vision , COO iLMS.app - AI- powered XR platform",
    photo: "anna_proch.webp",
    presentation: "Czy możemy zjeść ciastko i mieć ciastko? Innowacje, etyka i prawo w dobie sztucznej inteligencji",
    abstract: "", // todo
    bio: "Jestem CEO agencji digitalowej DP Vision, gdzie przekształcamy innowacyjne pomysły w wyjątkowe marki i produkty. Nasza specjalność to walidacja pomysłów biznesowych, branding, pomoc w pozyskaniu finansowania, a także wsparcie w budowie deep-techowych specjalizując się w SaaS'ach. Tworzymy centra inkubacji pomysłów przy dużych korporacjach wspierając rozwój, utrzymanie pracowników oraz zwiększając rentowność firmy. Szkolimy z zakresu nowych technologii: AI, VR, AR w biznesie i rozwoju pracowników. Jestem również co-founderką immersyjnej platformy edukacyjnej wspieranej przez AI. W swojej pracy skupiam się na optymalizacji procesów biznesowych, szukaniu oszczędności oraz wykorzystaniu najnowszych technologii, aby zwiększyć przewagę konkurencyjną. Skupiam się na solidnych danych, które są podstawą do budowy rozwiązań AI czy też ML u naszych klientów.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/annaproch/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 34,
    firstName: "Michał",
    lastName: "Zacher",
    title: "Panel dyskusyjny",
    company: "CEO at Vazco",
    photo: "michal_zacher.webp",
    presentation: "Czy możemy zjeść ciastko i mieć ciastko? Innowacje, etyka i prawo w dobie sztucznej inteligencji",
    abstract: "", // todo
    bio: "Założyciel Vazco, która specjalizuje się w projektowaniu i wdrażaniu doświadczeń oraz automatyzacji opartych na systemach agentowych i generatywnej sztucznej inteligencji (GenAI). Vazco koncentruje się na pełnym wykorzystaniu potencjału dużych modeli językowych (LLM), dzięki świetnemu UX, integrując je z tradycyjnymi interfejsami i automatyzacjami. Członek Grupy Roboczej ds AI przy Ministerstwie Cyfryzacji. Od lat pełni rolę członka zarządu ITCorner – organizacji zrzeszającej polskie firmy technologiczne. Jest również organizatorem ITCorner Yachting Challenge, najbardziej rozpoznawalnego w Polsce rejsu dla właścicieli firm z branży IT. Współtwórca podcastu Product Odyssey, gdzie zgłębia, jak tworzyć produkty cyfrowe fundamentalnie lepiej. W swojej karierze Michał doradzał setkom innowacyjnych projektów IT, pomagając skutecznie wykorzystywać nowe technologie do budowania realnej wartości i unikania pustego hype’u. Obecnie koncentruje się na doradztwie w zakresie tworzenia wartości z wykorzystaniem AI, łącząc najnowsze osiągnięcia technologii z realnymi potrzebami biznesowymi.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/michalzacher/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 35,
    firstName: "Krystian",
    lastName: "Bergmann",
    title: "Panel dyskusyjny",
    company: "CEO at Vazco",
    photo: "krystian_bergmann.webp",
    presentation: "Czy możemy zjeść ciastko i mieć ciastko? Innowacje, etyka i prawo w dobie sztucznej inteligencji",
    abstract: "", // todo
    bio: "Krystian to AI Consulting Lead w Netguru, czołowym europejskim software house, gdzie odpowiada za projekty AI, Machine Learning i Data. W ostatnich latach wdrażał projekty generatywnej sztucznej inteligencji w sektorze ubezpieczeń, edukacji i nieruchomości i facylitował warsztaty kreatywne z wykorzystaniem AI. Krystian jest częścią projektu Tech To The Rescue, gdzie prowadzi proces wykorzystania sztucznej inteligencji dla NGO wdrażających globalne zmiany (AI for Changemakers). Przez ponad dekadę pracował z największymi firmami technologicznymi takimi jak: Google, Apple, Huawei. Poza pracą w Netguru Krystian pracuje na Uniwersytecie SWPS, gdzie uczy Projektowania Usług i łączenia technologii z metodami human centric.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/krystianbergmann/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 36,
    firstName: "Natalia",
    lastName: "Buczel",
    title: "Panel dyskusyjny",
    company: "CC Business Services Director at Sii Poland",
    photo: "natalia_buczel.webp",
    presentation: "Debata Oksfordzka",
    abstract: "", // todo
    bio: "Natalia pełni funkcje Dyrektorki Centrum Kompetencyjnego Usług Biznesowych oraz Dyrektorki Regionalnej w Sii Poznań. Specjalizuje się w budowaniu usług BPO, transformacji procesów biznesowych, automatyzacji procesów (RPA), marketingu oraz zarządzaniu HR. Posiada tytuł magistra ekonomii oraz licencjat z socjologii. Z firmą Sii związana jest od 16 lat, pełniąc różnorodne role m.in. w sprzedaży, rekrutacji, zarządzaniu projektami i zespołami. Po pracy pasjonuje się rozwojem umiejętności (upskillingiem), wzmacnianiem pozycji kobiet oraz badmintonem.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/natalia-buczel-b44917b/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 37,
    firstName: "Mariusz",
    lastName: "Zyśk",
    title: "Panel dyskusyjny",
    company: "CTO @ Laurens Coster",
    photo: "mariusz_zysk.webp",
    presentation: "Debata Oksfordzka",
    abstract: "", // todo
    bio: "Technologiczny wizjoner, który chroni i maksymalizuje wartość danych powierzonych Laurens Coster. Z doskonałym poczuciem humoru, genialną osobowością i szeroką wiedzą techniczną, jest integralną częścią naszego zespołu managerskiego i siłą napędową do poszukiwania innowacji.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/mariusz-zy%C5%9Bk-991b9a78/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },
  {
    id: 38,
    firstName: "Maciej",
    lastName: "Majchrzak",
    title: "Panel dyskusyjny",
    company: "Tech Lead, Architect, Engineer - AWS / DevOps / AI / AEM",
    photo: "maciej_majchrzak.webp",
    presentation: "Debata Oksfordzka",
    abstract: "", // todo
    bio: "Maciej Majchrzak is an experienced technical lead working with web platforms for over 10 years. At VML, he leads and oversees teams of engineers delivering digital platforms for our clients. He has a deep knowledge of technology and software architectures but he's also very much interested in knowledge sharing and mentoring.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/maciejmajchrzak/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },  
  {
    id: 39,
    firstName: "Radek",
    lastName: "Wojtysiak",
    title: "Panel dyskusyjny",
    company: "Pomagam zrozumieć świat programistów | Front-end Dev",
    photo: "radek_wojtysiak.webp",
    presentation: "Debata Oksfordzka",
    abstract: "", // todo
    bio: "Programista i pasjonat technologii internetowych. Z wyboru front-end'owiec. Entuzjasta dzielenia się wiedzą. Trener języka JavaScript oraz prelegent. Pomaga zrozumieć świat programistów tym którzy programistami nie są. Autor biuletynu Kariera Developera na Linkedin.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/radekwojtysiak/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },  
  {
    id: 40,
    firstName: "Artur",
    lastName: "Ciachowski",
    title: "Konferansjer",
    company: "",
    photo: "artur_ciachowski.webp",
    presentation: "Konferansjer",
    abstract: "", // todo
    bio: "Absolwent dziennikarstwa z tytułem inżyniera. Wieloletni dziennikarz sportowy, który po latach zdecydował się przejść na 'ciemną stronę mocy', jaką jest budowanie marki i digital marketing. Tworzy komunikację z duszą, a na scenie czuje się jak ryba w wodzie. Marketing Manager w Inkubatorze Przedsiębiorczości inQUBE oraz specjalista od social mediów w IDENTT. Na co dzień również koordynator programu inkubcyjnego i mentor dla startupów na wczesnym etapie.",
    website: "https://janesmith.com",
    linkedin: "",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },    
  {
    id: 41,
    firstName: "Marta",
    lastName: "Tyc",
    title: "Praktyczny trening oddechowy",
    company: "",
    photo: "marta_tyc.webp",
    presentation: "Praktyczny trening oddechowy",
    abstract: "Praktyczny trening oddechowy", // todo
    bio: "Marta, z ponad 20-letnim doświadczeniem w zarządzaniu zasobami ludzkimi, głównie w branży IT, specjalizuje się w rozwoju liderów oraz tworzeniu kultury dobrostanu i samoświadomości w zespołach. Jako niezależny menedżer HR i certyfikowany trener Insights Discovery, łączy nowoczesne techniki szkoleniowe z jogą, medytacją i ćwiczeniami oddechowymi, aby wspierać efektywność zespołów i rozwój osobisty pracowników. Marta współpracuje z Fundacją Kwitnąca Polska, gdzie prowadzi warsztaty z job craftingu, pomagając uczestnikom świadomie kształtować swoje role zawodowe w sposób dopasowany do ich indywidualnych predyspozycji i potrzeb. Prowadzi również przestrzeń o nazwie Zaczarowany Bieślin, położoną nad malowniczym jeziorem. W tym wyjątkowym otoczeniu organizuje warsztaty rozwojowe, zapewniając uczestnikom sprzyjające warunki do pracy nad sobą, regeneracji i wzmocnienia osobistego dobrostanu.",
    website: "https://janesmith.com",
    linkedin: "https://www.linkedin.com/in/marta-tyc-she-her-78822111/",
    twitter: "https://twitter.com/janesmith",
    isFeatured: false,
    language: "PL"
  },   
]


export default SpeakersData;